// Auth0ProviderWithHistory.js

import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || '/club');
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || "dev-iv370w5svdscsbqr.us.auth0.com"}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || "8M5yZS2BKhPPm3aHx0JPml35OA6VllXo"}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE || 'https://your-api-identifier'}
      scope="openid profile email"
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage" // Utiliser localstorage pour persister les tokens
      useRefreshTokens={true} // Activer les refresh tokens
      useCookiesForTransactions={false} // Facultatif, selon votre configuration
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
