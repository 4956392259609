// App.js

import React, { useState, useEffect, Suspense, lazy } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ChakraProvider, Flex, Box, Skeleton } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import ScrollToTop from './components/ScrollToTop';
import Auth0ProviderWithHistory from './Auth0ProviderWithHistory';

// Lazy loading components
const HomePage = lazy(() => import('./HomePage'));
const InfinitHomePage = lazy(() => import('./components/InfinitHomePage.js')); // Nouveau import
const StructuredProductForm = lazy(() => import('./components/StructuredProductForm'));
const ProductList = lazy(() => import('./components/ProductList'));
const FundsList = lazy(() => import('./components/FundsList'));
const EditProduct = lazy(() => import('./components/EditProduct'));
const AddProductFiles = lazy(() => import('./components/AddProductFiles'));
const AddProductImages = lazy(() => import('./components/AddProductImages'));
const ProductAdminPage = lazy(() => import('./components/ProductAdminPage'));
const FundAdminPage = lazy(() => import('./components/FundAdminPage'));
const FundForm = lazy(() => import('./components/FundForm'));
const AddFundFiles = lazy(() => import('./components/AddFundFiles'));
const AddFundImages = lazy(() => import('./components/AddFundImages'));
const AddOrEditFundPromotionalInfo = lazy(() => import('./components/AddOrEditFundPromotionalInfo'));
const EditFundForm = lazy(() => import('./components/EditFundForm'));
const AddProductPromotionalInfo = lazy(() => import('./components/AddProductPromotionalInfo'));
const AddProductScenario = lazy(() => import('./components/AddProductScenario'));
const AddOrEditProductInsurer = lazy(() => import('./components/AddOrEditProductInsurer'));
const AddOrEditProductContact = lazy(() => import('./components/AddOrEditProductContact'));
const CreateClient = lazy(() => import('./components/CreateClient'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const News = lazy(() => import('./components/News'));
const LoginPage = lazy(() => import('./components/LoginPage'));
const ClientInfo = lazy(() => import('./components/ClientInfo'));
const CreateCompany = lazy(() => import('./components/CreateCompany'));
const SimpleChart = lazy(() => import('./components/SimpleChart'));
const Chatbot = lazy(() => import('./components/Chatbot'));
const Vocalchat = lazy(() => import('./components/Vocalchat'));
const BondForm = lazy(() => import('./components/BondForm'));
const AddBondFiles = lazy(() => import('./components/AddBondFiles'));
const AddBondImages = lazy(() => import('./components/AddBondImages'));
const EditBondForm = lazy(() => import('./components/EditBondForm'));
const AddOrEditBondPromotionalInfo = lazy(() => import('./components/AddOrEditBondPromotionalInfo'));
const BondsList = lazy(() => import('./components/BondsList'));
const BondAdminPage = lazy(() => import('./components/BondAdminPage'));
const ContactForm = lazy(() => import('./components/ContactForm'));
const ArticlesListPage = lazy(() => import('./components/ArticlesListPage'));
const ArticleDetailPage = lazy(() => import('./components/ArticleDetailPage'));
const ArticleFormPage = lazy(() => import('./components/ArticleFormPage'));
const ArticleAdminPage = lazy(() => import('./components/ArticleAdminPage'));
const CareerReport = lazy(() => import('./components/CareerReport'));
const NearbyProfessionals = lazy(() => import('./components/NearbyProfessionals'));
const InfinityHomePage = lazy(() => import('./components/InfinityHomePage'));
const ProfilClientForm = lazy(() => import('./components/ProfilClientForm'));
const ManagedInsuranceForm = lazy(() => import('./components/ManagedInsuranceForm'));
const AddManagedInsuranceFiles = lazy(() => import('./components/AddManagedInsuranceFiles'));
const AddManagedInsuranceImages = lazy(() => import('./components/AddManagedInsuranceImages'));
const Editinsurance = lazy(() => import('./components/Editinsurance'));
const AddOrEditManagedInsurancePromotionalInfo = lazy(() => import('./components/AddOrEditManagedInsurancePromotionalInfo'));
const ManagedInsuranceList = lazy(() => import('./components/ManagedInsurancesList'));
const ManagedInsuranceAdminPage = lazy(() => import('./components/ManagedInsuranceAdminPage'));
const PerformanceReportPage = lazy(() => import('./components/PerformanceReportPage'));
const InvestmentAIPage = lazy(() => import('./components/InvestmentAIPage'));
const OptimisationFiscaleForm = lazy(() => import('./components/OptimisationFiscaleInfinityForm'));
const PERPage = lazy(() => import('./components/PERPage'));
const AssuranceViePage = lazy(() => import('./components/AssuranceViePage'));
const PEAPage = lazy(() => import('./components/PEAPage'));
const CompteEpargnePage = lazy(() => import('./components/CompteEpargnePage.js'));
const LivretAPage = lazy(() => import('./components/LivretAPage.js'));
const AssuranceVieSubscription = lazy(() => import('./components/AssuranceVieSubscription.js'));
const AssuranceAdminPage = lazy(() => import('./components/AssuranceAdminPage')); // Nouveau import
const ClubPage = lazy(() => import('./components/ClubPage')); // Nouveau import
const PDFAnalyzer = lazy(() => import('./components/PDFAnalyzer')); // Nouvelle importation
const BacktestPage = lazy(() => import('./components/BacktestPage')); // Import the new BacktestPage

const AuthenticationWrapper = ({ children }) => {
  const { isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
  const [isClientCreated, setIsClientCreated] = useState(false);
  const [isCreatingClient, setIsCreatingClient] = useState(false);

  useEffect(() => {
    const createClientIfNeeded = async () => {
      if (isAuthenticated && !isClientCreated && !isCreatingClient) {
        setIsCreatingClient(true);
        try {
          const idTokenClaims = await getIdTokenClaims();
          const id_token = idTokenClaims.__raw;

          const response = await fetch(`${process.env.REACT_APP_API_URL}/client-create/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id_token }),
          });

          if (!response.ok) {
            throw new Error('Erreur lors de la création du profil client.');
          }

          setIsClientCreated(true);
          localStorage.setItem('clientCreated', 'true');
        } catch (error) {
          console.error('Erreur lors de la création du client:', error);
        } finally {
          setIsCreatingClient(false);
        }
      }
    };

    if (isAuthenticated && !localStorage.getItem('clientCreated')) {
      createClientIfNeeded();
    }
  }, [isAuthenticated, isClientCreated, isCreatingClient, getIdTokenClaims]);

  if (isLoading || isCreatingClient) {
    return (
      <Box>
        <Skeleton height="60px" />
        <Flex>
          <Skeleton width="250px" height="calc(100vh - 60px)" />
          <Box flex="1" p={8}>
            <Skeleton height="200px" />
            <Skeleton height="300px" mt={4} />
          </Box>
        </Flex>
      </Box>
    );
  }

  return children;
};

function AppContent() {
  const location = useLocation();
  const { user, isAuthenticated } = useAuth0();
  const isAdmin = isAuthenticated && user && user.email === 'alamikamil@gmail.com'; // Remplacez par votre logique

  // Détecter le domaine actuel
  const hostname = window.location.hostname;
  const isInfinitDomain = hostname === 'infinit-patrimoine.com' || hostname === 'www.infinit-patrimoine.com'; // Nouveau domaine

  // Mise à jour de la condition showNavbar
  const showNavbar = !(isInfinitDomain && location.pathname === '/') && // Ne pas afficher la Navbar sur la page d'accueil du nouveau domaine
    !['/plan-epargne-retraite', '/assurance-vie', '/plan-epargne-action', '/livret-a'].includes(location.pathname) &&
    !location.pathname.startsWith('/assurance-vie-souscription') &&
    !location.pathname.startsWith('/infinity') &&
    !location.pathname.startsWith('/infinit');

  return (
    <AuthenticationWrapper>
      <Box>
        {showNavbar && <Navbar isAdmin={isAdmin} />}
        <Flex>
          <Box 
            flex="1" 
            p={showNavbar ? 8 : 0} 
            ml={showNavbar ? { base: 0, md: 250 } : 0}
            mt={showNavbar ? { base: '60px', md: '60px' } : 0}
          >
            <Suspense fallback={
              <Box>
                <Skeleton height="200px" />
                <Skeleton height="300px" mt={4} />
              </Box>
            }>
              <Routes>
                {/* Home Page */}
                <Route path="/" element={isInfinitDomain ? <InfinitHomePage /> : <HomePage />} />

                {/* Structured Products */}
                <Route path="/create-product" element={<StructuredProductForm />} />
                <Route path="/products" element={<ProductList />} />
                <Route path="/edit-product/:productId" element={<EditProduct />} />
                <Route path="/add-files/:productId" element={<AddProductFiles />} />
                <Route path="/add-images/:productId" element={<AddProductImages />} />
                <Route path="/add-promotional-info/:productId" element={<AddProductPromotionalInfo />} />
                <Route path="/add-scenario/:productId" element={<AddProductScenario />} />
                <Route path="/add-insurer/:productId" element={<AddOrEditProductInsurer />} />
                <Route path="/add-contact/:productId" element={<AddOrEditProductContact />} />
                <Route path="/product-admin" element={<ProductAdminPage />} />

                {/* Funds */}
                <Route path="/fund-admin" element={<FundAdminPage />} />
                <Route path="/create-fund" element={<FundForm />} />
                <Route path="/liste-meilleurs-fonds-et-etf" element={<FundsList />} />
                <Route path="/fund-files/:fundId" element={<AddFundFiles />} />
                <Route path="/fund-images/:fundId" element={<AddFundImages />} />
                <Route path="/fund-promotional-info/:fundId" element={<AddOrEditFundPromotionalInfo />} />
                <Route path="/edit-fund/:fundId" element={<EditFundForm />} />
                <Route path="/fund-performance/:ticker" element={<SimpleChart />} />

                {/* Bonds */}
                <Route path="/bonds" element={<BondsList />} />
                <Route path="/bond-admin" element={<BondAdminPage />} />
                <Route path="/create-bond" element={<BondForm />} />
                <Route path="/bond-files/:bondId" element={<AddBondFiles />} />
                <Route path="/bond-images/:bondId" element={<AddBondImages />} />
                <Route path="/edit-bond/:bondId" element={<EditBondForm />} />
                <Route path="/bond-promotional-info/:bondId" element={<AddOrEditBondPromotionalInfo />} />

                {/* Managed Insurances */}
                <Route path="/gestion-pilotee-sous-mandat/" element={<ManagedInsuranceList />} />
                <Route path="/managed-insurance-admin" element={<ManagedInsuranceAdminPage />} />
                <Route path="/create-managed-insurance" element={<ManagedInsuranceForm />} />
                <Route path="/managed-insurance-files/:insuranceId" element={<AddManagedInsuranceFiles />} />
                <Route path="/managed-insurance-images/:insuranceId" element={<AddManagedInsuranceImages />} />
                <Route path="/edit-managed-insurance/:insuranceId" element={<Editinsurance />} />
                <Route path="/managed-insurance-promotional-info/:insuranceId" element={<AddOrEditManagedInsurancePromotionalInfo />} />

                {/* Articles */}
                <Route path="/articles" element={<ArticlesListPage />} />
                <Route path="/article-details/:slug" element={<ArticleDetailPage />} />
                <Route path="/create-article" element={<ArticleFormPage />} />
                <Route path="/edit-article/:id" element={<ArticleFormPage />} />
                <Route path="/article-admin" element={<ArticleAdminPage />} />

                {/* Client and Company */}
                <Route path="/create-client" element={<CreateClient />} />
                <Route path="/create-company" element={<CreateCompany />} />
                <Route path="/client-info" element={<ClientInfo />} />

                {/* Other Pages */}
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/news" element={<News />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/chatbot" element={<Chatbot />} />
                <Route path="/vocalchat" element={<Vocalchat />} />
                <Route path="/contact" element={<ContactForm />} />
                <Route path="/career-report" element={<CareerReport />} />
                <Route path="/cgp-trouver-apporteurs-daffaire-a-proximite" element={<NearbyProfessionals />} />
                <Route path="/infinity" element={<InfinityHomePage />} />
                <Route path="/profil-client" element={<ProfilClientForm />} />
                <Route path="/rapport-adequation" element={<PerformanceReportPage />} />
                <Route path="/investment-ai" element={<InvestmentAIPage />} />
                <Route path="/optimisation-fiscale" element={<OptimisationFiscaleForm />} />

                {/* Financial Products Pages */}
                <Route path="/plan-epargne-retraite" element={<PERPage />} />
                <Route path="/assurance-vie" element={<AssuranceViePage />} />
                <Route path="/plan-epargne-action" element={<PEAPage />} />
                <Route path="/compte-epargne" element={<CompteEpargnePage />} />
                <Route path="/Livret-A" element={<LivretAPage />} />

                {/* Assurance Vie Subscription */}
                <Route path="/assurance-vie-souscription" element={<AssuranceVieSubscription />} />
                <Route path="/assurance-vie-souscription/:id" element={<AssuranceVieSubscription />} />
                <Route path="/assurance-admin" element={<AssuranceAdminPage />} />

                {/* Infinit Home Page (Autre Route) */}
                <Route path="/infinit" element={<InfinitHomePage />} />

                {/* Infinit club Page (Autre Route) */}
                <Route path="/club" element={<ClubPage />} />

               
                <Route path="/club" element={<ClubPage />} />

        
                {/* Route pour le PDFAnalyzer */}
                <Route path="/analyze-pdfs" element={<PDFAnalyzer />} />

                <Route path="/backtest-portfolio" element={<BacktestPage />} /> 

                
              </Routes>
            </Suspense>
          </Box>
        </Flex>
      </Box>
    </AuthenticationWrapper>
  );
}

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Auth0ProviderWithHistory>
          <ScrollToTop />
          <AppContent />
        </Auth0ProviderWithHistory>
      </Router>
    </ChakraProvider>
  );
}

export default function Auth0WrappedApp() {
  return <App />;
}
