import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  VStack,
  Text,
  Icon,
  Link as ChakraLink,
  useColorModeValue,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Flex,
  HStack,
  IconButton,
  Divider,
  useBreakpointValue,
  Spinner,
} from '@chakra-ui/react';
import {
  FaHome,
  FaSignOutAlt,
  FaSignInAlt,
  FaExchangeAlt,
  FaNewspaper,
  FaUniversity,
  FaEnvelope,
  FaBook,
  FaBars,
  FaUser,
  FaRobot,
  FaChartLine,
  FaLightbulb,
  FaCoins,
  FaMoneyBillWave,
  FaShieldAlt,
  FaKey,
} from 'react-icons/fa';
import { useAuth0 } from '@auth0/auth0-react';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

function Navigation() {
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect, logout, isLoading } = useAuth0();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const displayMode = useBreakpointValue({ base: 'mobile', md: 'desktop' });
  const location = useLocation();

  // Définition de la couleur principale (ancien bleu)
  const primaryColor = '#006c87';

  // Palette de couleurs mise à jour avec la nouvelle tonalité
  const linkColor = useColorModeValue('gray.700', 'gray.300');
  const activeLinkColor = useColorModeValue('white', 'white');
  const activeLinkBg = useColorModeValue(`${primaryColor}`, `${primaryColor}`);
  const sidebarBg = useColorModeValue('whiteAlpha.900', 'gray.800');
  const hoverBgColor = useColorModeValue(
    `${primaryColor}1A`,
    `${primaryColor}33`
  ); // Utilisation d'opacité
  const shadowColor = useColorModeValue(
    'rgba(0, 0, 0, 0.1)',
    'rgba(255, 255, 255, 0.1)'
  );
  const dividerColor = useColorModeValue('gray.200', 'gray.700');
  const scrollbarThumbColor = useColorModeValue(
    `${primaryColor}`,
    `${primaryColor}`
  );
  const categoryTitleColor = useColorModeValue(
    `${primaryColor}`,
    `${primaryColor}`
  );
  const navBgColor = useColorModeValue(`${primaryColor}`, `${primaryColor}`);
  const navTextColor = useColorModeValue('white', 'white');
  const buttonBgColor = useColorModeValue('white', 'gray.700');
  const buttonTextColor = useColorModeValue(`${primaryColor}`, 'white');
  const buttonHoverBgColor = useColorModeValue('gray.100', 'gray.600');

  if (isLoading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  const navCategories = [
    {
      title: '',
      items: [{ icon: FaHome, label: 'Accueil', path: '/club' }],
    },
    {
      title: 'Academy',
      items: [
        { icon: FaShieldAlt, label: 'Assurance Vie', path: '/assurance-vie' },
        { icon: FaKey, label: 'Livret A', path: '/livret-a' },
        {
          icon: FaMoneyBillWave,
          label: 'PER',
          path: '/plan-epargne-retraite',
        },
        { icon: FaCoins, label: 'PEA', path: '/plan-epargne-action' },
        { icon: FaNewspaper, label: 'Actualités Boursières', path: '/news' },
        { icon: FaBook, label: 'Articles', path: '/articles' },
      ],
    },
    {
      title: 'Opportunités',
      items: [
        {
          icon: FaChartLine,
          label: 'Gestion Pilotée',
          path: '/gestion-pilotee-sous-mandat/',
        },
        {
          icon: FaExchangeAlt,
          label: 'Fonds/ETFs',
          path: '/liste-meilleurs-fonds-et-etf',
        },
        { icon: FaUniversity, label: 'Obligations', path: '/bonds' },
      ],
    },
    {
      title: 'Outils Intelligents',
      items: [
        { icon: FaRobot, label: 'FinGPT', path: '/chatbot' },
        {
          icon: FaLightbulb,
          label: "Idées d'Investissement",
          path: '/investment-ai',
        },
      ],
    },
    {
      title: 'Mon Compte',
      items: [
        { icon: FaUser, label: 'Profil', path: '/profil-client' },
        { icon: FaEnvelope, label: 'Nous Contacter', path: '/contact' },
        isAuthenticated && {
          icon: FaSignOutAlt,
          label: 'Déconnexion',
          path: '/logout',
          action: () => logout({ returnTo: window.location.origin }),
        },
      ].filter(Boolean),
    },
  ];

  const handleNavigation = (path, action) => {
    if (action) {
      action();
    } else {
      navigate(path);
    }
    if (displayMode === 'mobile') onClose();
  };

  const isActive = (path) => {
    if (path === '/logout') {
      return false;
    }
    return location.pathname === path;
  };

  const AuthButtons = () => {
    return !isAuthenticated ? (
      <Button
        as={motion.button}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        variant="solid"
        bg={buttonBgColor}
        color={buttonTextColor}
        leftIcon={<FaSignInAlt />}
        onClick={() => loginWithRedirect()}
        borderRadius="full"
        boxShadow="md"
        _hover={{
          bg: buttonHoverBgColor,
          boxShadow: 'lg',
        }}
        fontFamily="Helvetica, Arial, sans-serif"
      >
        Connexion
      </Button>
    ) : null;
  };

  const SidebarContent = () => (
    <VStack spacing={6} align="stretch" width="100%" mt={4}>
      {navCategories.map((category, index) => (
        <Box key={index}>
          {category.title && (
            <Text
              fontSize="sm"
              fontWeight="bold"
              color={categoryTitleColor}
              mb={2}
              textTransform="uppercase"
              letterSpacing="wide"
              fontFamily="Helvetica, Arial, sans-serif"
            >
              {category.title}
            </Text>
          )}
          {category.items.map((item) => (
            <ChakraLink
              key={item.label}
              as={motion.div}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              px={3}
              py={2}
              display="flex"
              alignItems="center"
              borderRadius="md"
              bg={isActive(item.path) ? activeLinkBg : 'transparent'}
              color={isActive(item.path) ? activeLinkColor : linkColor}
              fontWeight="medium"
              transition="all 0.3s"
              _hover={{ bg: hoverBgColor, textDecoration: 'none' }}
              onClick={() => handleNavigation(item.path, item.action)}
              fontFamily="Helvetica, Arial, sans-serif"
            >
              <Icon as={item.icon} mr={3} />
              {item.label}
            </ChakraLink>
          ))}
          {index < navCategories.length - 1 && (
            <Divider my={4} borderColor={dividerColor} />
          )}
        </Box>
      ))}
      {displayMode === 'mobile' && (
        <VStack spacing={4} align="stretch">
          <AuthButtons />
        </VStack>
      )}
    </VStack>
  );

  return (
    <>
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="0.5rem 1rem"
        bg={navBgColor}
        color={navTextColor}
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex="docked"
        boxShadow={`0 2px 10px ${shadowColor}`}
        width="100%"
      >
        <Flex
          align="center"
          mr={5}
          onClick={() => navigate('/club')}
          cursor="pointer"
        >
          <Text
            fontSize="2xl"
            fontWeight="extrabold"
            color={navTextColor}
            fontFamily="Helvetica, Arial, sans-serif"
          >
            Infinit Club
          </Text>
        </Flex>

        <HStack spacing={4}>
          <AuthButtons />
          {displayMode === 'mobile' && (
            <IconButton
              aria-label="Ouvrir le menu"
              icon={<FaBars />}
              onClick={onOpen}
              variant="ghost"
              color={navTextColor}
              _hover={{ bg: hoverBgColor }}
              _active={{ bg: activeLinkBg, color: activeLinkColor }}
            />
          )}
        </HStack>
      </Flex>

      {displayMode === 'mobile' ? (
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay>
            <DrawerContent
              bg={sidebarBg}
              boxShadow={`2px 0 10px ${shadowColor}`}
            >
              <DrawerCloseButton />
              <DrawerHeader
                borderBottomWidth="1px"
                fontFamily="Helvetica, Arial, sans-serif"
              >
                Menu
              </DrawerHeader>
              <DrawerBody>
                <SidebarContent />
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      ) : (
        <Box
          bg={sidebarBg}
          w={250}
          px={4}
          py={6}
          boxShadow={`2px 0 10px ${shadowColor}`}
          h="calc(100vh - 60px)"
          position="fixed"
          top="60px"
          left={0}
          overflowY="auto"
          sx={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: scrollbarThumbColor,
              borderRadius: '24px',
            },
          }}
        >
          <SidebarContent />
        </Box>
      )}
    </>
  );
}

export default Navigation;
